export default [
  {
    path: '/',
    component: ()=>import('./components/layout.vue'),
    children:[
      {
        path:'',
        component: ()=>import('./components/free.vue'),
        // children: [
        //   {
        //     path:'',
        //     component: ()=>import('./pages/code.vue')
        //   },
        //   {
        //     path: 'result',
        //     component: ()=>import('./pages/code-result.vue')
        //   },
        //   {
        //     path: 'success',
        //     component: ()=>import('./pages/code-success.vue')
        //   },
        // ]
      },
      {
        path: 'quit',
        component: ()=>import('./components/quit.vue')
      }
      // {
      //   path: 'success',
      //   component: ()=>import('./components/success.vue')
      // }
      // {
      //   path: 'page',
      //   component: ()=>import('./pages/content.vue'),
      //   children: [
      //     {
      //       path: 'term',
      //       component: ()=>import('./pages/terms.vue')
      //     },
      //     {
      //       path: 'privacy',
      //       component: ()=>import('./pages/privacy.vue')
      //     },
      //   ]
      // }
    ]
  }
]